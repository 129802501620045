import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Grid, IconButton, Menu, MenuItem, Paper, TableContainer, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import { ExportButton, SearchField } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";
import { StyledCard } from "../../../App";
import AddDevice from "./AddDevice";
import Attach from "./Attach";
import ColumnSelectBtn from "./ColumnSelectBtn";
import { $deviceTableDataStore } from "../../../store/DeviceTableStore";
import { $membersDataStore } from "../../../store/MembersStore";
import { $loginUserStore } from "../../../store/ResponseData";
import MainContentWrap from "../../ui/wrappers/MainContentWrap";
import { exportDevices } from "../../../api-actions/DevicesApi";
import Table from "../../ui/table/Table";
import { type MRT_ColumnDef } from "material-react-table";
import { formatDateTime } from "../../../utils/utils";
import { Circle } from "@mui/icons-material";
import { Device, DeviceAttach } from "../../../types/device";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

require("dayjs/locale/ru");
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("ru");

function timeAgo(time: Date | string) {
    const dayjsMsk = dayjs.tz(time, "Europe/Moscow");
    return dayjsMsk.fromNow();
}

const DevicesList = observer(() => {
    const [exportLoading, setExportLoading] = useState(false);
    const { loadingDeviceTable, devicePage, deviceRowsPerPage, deviceSort, deviceSearch, deviceData, tableColumns } =
        $deviceTableDataStore;

    const tableColumnsInit: MRT_ColumnDef<Device>[] = [
        {
            accessorKey: "imei",
            header: "Идентификатор",
            size: 100,
            enableHiding: true,
        },
        {
            accessorKey: "model",
            header: "Модель",
            size: 120,
            enableHiding: true,
        },
        {
            accessorKey: "phone",
            header: "Номер телефона",
            size: 100,
            enableHiding: $loginUserStore.isAdmin(),
        },
        {
            accessorKey: "name",
            header: "Название",
            size: 120,
            enableHiding: true,
        },
        {
            accessorKey: "description",
            header: "Описание",
            size: 120,
            enableHiding: true,
        },
        {
            accessorKey: "status_date",
            header: "Статус",
            size: 120,
            enableHiding: true,
            accessorFn: (row) => (
                <Tooltip arrow title={row.status_date ? formatDateTime(row.status_date, "", "d.m.y H:i:s") : "Никогда"}>
                    <span
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <Circle
                            htmlColor={row.status === "online" ? "green" : row.status === "offline" ? "red" : "black"}
                            style={{
                                fontSize: 14,
                                marginRight: 5,
                            }}
                        />
                        <span>{row.status_date ? timeAgo(row.status_date) : ""}</span>
                    </span>
                </Tooltip>
            ),
        },
        {
            accessorKey: "last_online",
            header: "Координаты",
            size: 100,
            enableHiding: true,
            accessorFn: (row) => (
                <Tooltip arrow title={row.last_online ? formatDateTime(row.last_online, "", "d.m.y H:i:s") : "Никогда"}>
                    <span>{row.last_online ? timeAgo(row.last_online) : "Никогда"}</span>
                </Tooltip>
            ),
        },
        {
            accessorKey: "attach",
            header: "Сотрудник",
            size: 350,
            enableSorting: false,
            enableHiding: true,
            accessorFn: (row) => {
                let attaches: string[] = [];
                if (row.attach.items.length !== undefined) {
                    row.attach.items.map((attach: DeviceAttach) => {
                        return attaches.push(
                            attach.name +
                                " (с " +
                                formatDateTime(attach.start, "", "d.m.Y H:i:s") +
                                (attach.end !== "" ? " по " + formatDateTime(attach.end, "", "d.m.Y H:i:s") : "") +
                                ")"
                        );
                    });
                }
                return attaches.map((item) => {
                    return <p style={{ margin: 0 }}>{item}</p>;
                });
            },
        },
        {
            accessorKey: "battery",
            header: "Заряд",
            size: 30,
            enableHiding: true,
        },
    ];

    useEffect(() => {
        let tableColumns = tableColumnsInit;

        // убираем колонки из списка выбора колонок, которые предназначены только для админа
        if (!$loginUserStore.isAdmin()) {
            tableColumns = tableColumns.filter((column) => !["phone"].includes(column?.accessorKey ?? ""));
        }

        $deviceTableDataStore.setTableColumns(tableColumns);
    }, []);

    useEffect(() => {
        $deviceTableDataStore.updateData();
    }, [devicePage, deviceRowsPerPage, deviceSort, deviceSearch]);

    const changeTableSort = useCallback(
        (key: string) => {
            $deviceTableDataStore.setSort({
                by: key,
                order: deviceSort.by !== key ? "asc" : deviceSort.order === "asc" ? "desc" : "asc",
            });
        },
        [deviceSort]
    );

    const handleChangePage = (v: number) => {
        $deviceTableDataStore.setPage(v);
    };

    const handleChangeRowsPerPage = (v: number) => {
        $deviceTableDataStore.setRowsPerPage(v);
        $deviceTableDataStore.setPage(0);
    };

    const exportTable = () => {
        setExportLoading(true);
        exportDevices()
            .then((response) => {
                const data = response?.data?.data;

                require("downloadjs")(atob(data.content), data.name, data.content_type);
                setExportLoading(false);
            })
            .catch(() => setExportLoading(false));
    };

    return (
        <MainContentWrap>
            <>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    style={{
                        paddingBottom: "1em",
                        paddingLeft: ".75em",
                    }}
                >
                    <Grid item>
                        <Grid container direction={"row"} spacing={2} alignItems={"baseline"}>
                            <Grid item>
                                <h3>Устройства</h3>
                            </Grid>
                            {$loginUserStore.isAdmin() ? (
                                <Grid item>
                                    <IconButton onClick={() => $deviceTableDataStore.setAddNewDevice(true)}>
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </Grid>
                            ) : null}
                            <Grid item>
                                <ColumnSelectBtn />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container flexDirection="row">
                            <Grid item style={{ marginRight: 15 }}>
                                <Tooltip title="Выгрузить все устройства в Excel" arrow>
                                    <Box
                                        sx={{
                                            height: {
                                                xs: 50,
                                                md: 40,
                                            },
                                            display: "block",
                                        }}
                                    >
                                        <ExportButton
                                            onClick={exportTable}
                                            loading={exportLoading}
                                            disabled={exportLoading}
                                            style={{
                                                margin: 0,
                                            }}
                                        />
                                    </Box>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <SearchField
                                    value={deviceSearch}
                                    callback={(value) => $deviceTableDataStore.setSearch(value)}
                                    callbackTimeout={700}
                                    style={{
                                        width: 300,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <StyledCard>
                    <TableContainer component={Paper}>
                        <Table
                            data={deviceData?.items}
                            columns={tableColumns}
                            loading={loadingDeviceTable}
                            sortProps={deviceSort}
                            pageIndex={devicePage}
                            pageSize={deviceRowsPerPage}
                            changeTableSort={changeTableSort}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            rowCount={deviceData?.pagination.total! ?? 0}
                            enableRowActions={
                                $loginUserStore.isAdmin() ||
                                $loginUserStore.isUkWorker() ||
                                $loginUserStore.isPoWorker() ||
                                $loginUserStore.isHeadRegion()
                            }
                            renderRowActionMenuItems={({ closeMenu, row }) => [
                                <MenuItem
                                    onClick={() => {
                                        $deviceTableDataStore.setEditDeviceData(row.original);
                                        $deviceTableDataStore.setEditDevice(true);
                                        closeMenu();
                                    }}
                                >
                                    Редактировать
                                </MenuItem>,
                                row.original?.can_attach && $loginUserStore.isAdmin() && (
                                    <MenuItem
                                        onClick={() => {
                                            $deviceTableDataStore.setEditDeviceData(row.original);
                                            $membersDataStore.setAttach(true);
                                            closeMenu();
                                        }}
                                    >
                                        Закрепить сотрудника
                                    </MenuItem>
                                ),
                                row.original?.can_detach && $loginUserStore.isAdmin() && (
                                    <MenuItem
                                        onClick={() => {
                                            $deviceTableDataStore.setEditDeviceData(row.original);
                                            $membersDataStore.setDetach(true);
                                            closeMenu();
                                        }}
                                    >
                                        Открепить сотрудника
                                    </MenuItem>
                                ),
                            ]}
                        />
                    </TableContainer>
                    {($deviceTableDataStore.addNewDevice || $deviceTableDataStore.editDevice) && <AddDevice />}
                    {($membersDataStore.attach || $membersDataStore.detach) && <Attach />}
                </StyledCard>
            </>
        </MainContentWrap>
    );
});

export default DevicesList;
