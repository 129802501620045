import { Card, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import "./App.css";
import { generatePath, Navigate, Route, Routes } from "react-router-dom";
import { CustomCircularProgress } from "./components/ui/CustomCircularProgress";
import MainMenu from "./components/MainMenu";
import MobileMenu from "./components/MobileMenu";
import ContractDetail from "./components/pages/contracts/ContractDetail";
import Contracts from "./components/pages/contracts/Contracts";
import DevicesList from "./components/pages/devices/DevicesList";
import Login from "./components/pages/login/Login";
import Members from "./components/pages/members/Members";
import Monitoringv3 from "./components/pages/monitoring/Monitoringv3";
import NotFound from "./components/pages/NotFound";
import AddNewZone from "./components/pages/zone/AddNewZone";
import ZoneDetail from "./components/pages/zone/ZoneDetail";
import ZoneList from "./components/pages/zone/ZoneList";
import { $mainMenuStore } from "./store/MenuStore";
import { $organisationsStore } from "./store/OrganisationsStore";
import { $loginUserStore } from "./store/ResponseData";
import { $ukStore } from "./store/UkStore";
import { AppRoute } from "./utils/const";
import { useNavigate } from "react-router";

export const StyledCard = styled(Card)({
    height: "100%",
    border: "1px solid #5ac8fa",
    boxShadow: "0 0 10px 0px rgb(0 0 0 / 9%)",
    borderRadius: 15,
    padding: 15,
    marginBottom: 15,

    "& .MuiTableCell-head": {
        background: "#f6fbff",
        lineHeight: "1.1rem",
        borderBottom: "1px solid rgba(32, 160, 255, 0.5)",
        fontWeight: 800,
        whiteSpace: "nowrap",
        padding: 10,
    },

    "& .MuiTableCell-body": {
        padding: 10,
    },
});

const App = observer((): JSX.Element => {
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();

    const { token, data: authData, isLogout } = $loginUserStore;

    useEffect(() => {
        setLoading(true);
        const serverLoginResponseString = localStorage.getItem("userData") ?? undefined;
        if (serverLoginResponseString === undefined) {
            setLoading(false);
            return;
        }

        const serverLoginResponse = JSON.parse(serverLoginResponseString);
        $loginUserStore.setData(serverLoginResponse.data);

        if (token) {
            $organisationsStore.getData();
            $ukStore.getData();
        }

        setLoading(false);
    }, [token]);

    useEffect(() => {
        if (isLogout) {
            navigate(AppRoute.Root);
            $loginUserStore.setIsLogout(false);
        }
    }, [isLogout]);

    if (authData && Object.keys(authData).length === 0) {
        setLoading(false);
        return <></>;
    }

    if (authData === undefined && !loading) {
        return <Login />;
    }

    if (loading) {
        return <CustomCircularProgress />;
    }

    return (
        <Grid container direction="row" justifyContent="space-between">
            <Grid
                item
                xs={12}
                sx={{
                    display: {
                        xs: "block",
                        md: "none",
                    },
                }}
            >
                <MobileMenu />
            </Grid>
            <Grid
                item
                md={$mainMenuStore.menuStatus ? 3 : 1}
                lg={$mainMenuStore.menuStatus ? 2 : 1}
                sx={{
                    display: {
                        xs: "none",
                        md: "block",
                    },
                }}
            >
                <MainMenu />
            </Grid>
            <Grid
                item
                xs={12}
                md={$mainMenuStore.menuStatus ? 9 : 11}
                lg={$mainMenuStore.menuStatus ? 10 : 11}
                sx={{
                    paddingTop: {
                        xs: "75px",
                        md: 0,
                    },
                }}
            >
                <Grid container direction="column">
                    <Grid
                        item
                        style={{
                            height: "100%",
                            width: "100%",
                            minHeight: "100vh",
                        }}
                    >
                        <Routes>
                            <Route path={AppRoute.Root}>
                                <Route index element={<Navigate to={generatePath(AppRoute.Monitoring)} />} />
                                <Route path={AppRoute.Login} element={<Login />} />
                                <Route path={AppRoute.ZoneList} element={<ZoneList />} />
                                <Route path={AppRoute.ZoneNew} element={<AddNewZone />} />
                                <Route path={AppRoute.ZoneDetail} element={<ZoneDetail />} />
                                <Route path={AppRoute.Monitoring} element={<Monitoringv3 />} />
                                <Route
                                    path={AppRoute.Monitoringv2}
                                    element={<Navigate to={generatePath(AppRoute.Monitoring)} />}
                                />
                                <Route
                                    path={AppRoute.Monitoringv3}
                                    element={<Navigate to={generatePath(AppRoute.Monitoring)} />}
                                />
                                <Route path={AppRoute.ContractList} element={<Contracts />} />
                                <Route path={AppRoute.ContractDetail} element={<ContractDetail />} />
                                <Route path={AppRoute.Members} element={<Members />} />
                                <Route path={AppRoute.Devices} element={<DevicesList />} />
                            </Route>
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});

export default App;
