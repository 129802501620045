import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ButtonSquare } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { attachDevice, detachDevice } from "../../../api-actions/DevicesApi";
import { $deviceTableDataStore } from "../../../store/DeviceTableStore";
import { $membersDataStore } from "../../../store/MembersStore";
import { $organisationsStore } from "../../../store/OrganisationsStore";
import { $ukStore } from "../../../store/UkStore";
import { AddMemberToDevice } from "../../../types/addMemberToDevice";
import Notification from "../../ui/Notification";
import Autocomplete from "../../ui/Autocomplete";
import { Item } from "../../../types/ui/autocomplete";
import DateTimePicker from "../../ui/DateTimePicker";

const StyledDialog = styled(Dialog)({
    height: "auto",
    padding: "15px",
    margin: "0 auto",
});

const Attach = observer(() => {
    const { membersData, membersloadingTable, detach: isDetach } = $membersDataStore;
    const search = isDetach ? $deviceTableDataStore?.editDeviceData.current_user! : "";
    const minDateTime = $deviceTableDataStore.editDeviceData?.min_attach_date;
    const [form, setForm] = useState<AddMemberToDevice>({
        device: $deviceTableDataStore?.editDeviceData.guid!,
        date: "",
        user: "",
        user_name: "",
        organisation: "",
        organisation_name: "",
    });

    const [errors, setErrors] = useState<Record<string, string>>({});
    const [open, setOpen] = useState(false);

    const [userSelected, setUserSelected] = useState<Item>({
        id: "",
        label: "",
    });

    const [organisationSelected, setOrganisationSelected] = useState<Item>({
        id: "",
        label: "",
    });

    useEffect(() => {
        if (isDetach && membersData?.items.length === 1) {
            setForm({
                ...form,
                user: membersData?.items[0].guid!,
                user_name: membersData?.items[0].name!,
                organisation: membersData?.items[0].organisation.guid!,
                organisation_name: membersData?.items[0].organisation.name!,
            });
            setUserSelected({
                id: membersData?.items[0].guid,
                label: membersData?.items[0].name,
            });
            setOrganisationSelected({
                id: membersData?.items[0].organisation.guid,
                label: membersData?.items[0].organisation.name,
            });
        }
    }, [membersData?.items, isDetach]);

    useEffect(() => {
        if (form.organisation !== "" || isDetach) {
            $membersDataStore.getData("1", "name", "asc", "99999", search, form.organisation);
        }
    }, [form.organisation, isDetach, search]);

    const { data: uks } = $ukStore;
    const { data: organisations } = $organisationsStore;

    const fullOrgList = useMemo(() => {
        const ukList = uks?.map((item) => {
            return {
                label: item.name,
                id: item.guid,
            };
        });
        const organisationList = organisations?.map((item) => {
            return {
                label: item.name,
                id: item.guid,
            };
        });

        if (ukList !== undefined && organisationList !== undefined) {
            return ukList.concat(organisationList);
        }

        return [];
    }, [uks, organisations]);

    const members = useMemo(() => {
        if (membersData?.items.length !== undefined) {
            return membersData?.items.map((item) => {
                return {
                    label: item.name,
                    id: item.guid,
                };
            });
        }
    }, [membersData?.items]);

    const setFormValue = useCallback(
        (name: string, value: string | EventTarget) => {
            setForm({ ...form, [name]: value });

            if (value !== "" && errors.hasOwnProperty(name)) {
                setErrors({
                    ...errors,
                    [name]: "",
                });
            }
        },
        [form, errors]
    );

    const handleOrganisationChange = useCallback(
        (value: Item) => {
            setUserSelected({
                id: "",
                label: "",
            });

            if (!value) {
                value = {
                    id: "",
                    label: "",
                };
            }

            setOrganisationSelected(value);
            setForm({
                ...form,
                organisation: (value as any)?.id,
                organisation_name: (value as any)?.label,
                user: "",
                user_name: "",
            });
        },
        [form]
    );

    const handleUserChange = useCallback(
        (value: Item) => {
            if (!value) {
                value = {
                    id: "",
                    label: "",
                };
            }
            setUserSelected(value);
            setForm({
                ...form,
                user: (value as any)?.id,
                user_name: (value as any)?.label,
            });
        },
        [form]
    );

    const handleSubmit = async () => {
        let newErrors: Record<string, string> = {};
        if (form.organisation === "") {
            newErrors.organisation = 'Поле "Организация" обязательно для заполнения';
        }
        if (form.user === "") {
            newErrors.user = 'Поле "Сотрудник" обязательно для заполнения';
        }
        if (form.date === "") {
            newErrors.date = 'Поле "' + (isDetach ? "Дата завершения" : "Дата начала") + '" обязательно для заполнения';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length <= 0) {
            $deviceTableDataStore.setLoadingDeviceTable(true);
            if (isDetach) {
                await detachDevice(form).then((response) => {
                    $deviceTableDataStore.updateData();
                    $membersDataStore.setDetach(false);

                    if (response.message) {
                        Notification({
                            title: response.message,
                            type: "error",
                        });
                    } else {
                        Notification({
                            title: "Сотрудник откреплён",
                            type: "success",
                            duration: 30000,
                        });
                    }
                });
            } else {
                await attachDevice(form).then((response) => {
                    $deviceTableDataStore.updateData();
                    $membersDataStore.setAttach(false);

                    if (response.message) {
                        Notification({
                            title: response.message,
                            type: "error",
                        });
                    } else {
                        Notification({
                            title: "Сотрудник закреплён",
                            type: "success",
                            duration: 30000,
                        });
                    }
                });
            }
            $deviceTableDataStore.setLoadingDeviceTable(false);
        }
    };

    return (
        <StyledDialog
            open={$membersDataStore.attach || $membersDataStore.detach}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
                    width: 692,
                    overflowY: "unset",
                },
            }}
            keepMounted={true}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            maxWidth={false}
            scroll="body"
        >
            <IconButton
                aria-label="close"
                style={{
                    position: "absolute",
                    right: "-45px",
                    top: "-45px",
                    color: "white",
                }}
                onClick={() => {
                    $membersDataStore.setAttach(false);
                    $membersDataStore.setDetach(false);
                }}
            >
                <CloseIcon fontSize="large" />
            </IconButton>

            <Grid container justifyContent={"center"}>
                <Grid item xs={9}>
                    <Grid container direction={"column"} justifyContent={"center"} spacing={2}>
                        <Grid item xs={7}>
                            <DialogTitle
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Typography
                                    variant={"h1"}
                                    style={{
                                        fontSize: "34px",
                                        lineHeight: "36px",
                                        letterSpacing: "0",
                                        fontWeight: "600",
                                        color: "#20a0ff",
                                    }}
                                    component={"span"}
                                >
                                    {isDetach ? "Открепить сотрудника" : "Закрепить сотрудника"}
                                </Typography>
                            </DialogTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                direction={"row"}
                                alignItems={"center"}
                                spacing={2}
                                justifyContent={"space-between"}
                            >
                                <Grid item xs={12}>
                                    <TextField
                                        placeholder="Идентификатор устройства"
                                        label="Идентификатор устройства"
                                        required
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        size="medium"
                                        variant="outlined"
                                        id="device"
                                        name="device"
                                        fullWidth
                                        value={$deviceTableDataStore.editDeviceData?.imei}
                                        error={errors.device?.length > 0}
                                        helperText={errors.device?.length > 0 ? errors.device : false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                direction={"row"}
                                alignItems={"center"}
                                spacing={2}
                                justifyContent={"space-between"}
                            >
                                <Grid item xs={12}>
                                    <TextField
                                        placeholder="Название устройства"
                                        label="Название устройства"
                                        required
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        size="medium"
                                        variant="outlined"
                                        id="device_name"
                                        name="device_name"
                                        fullWidth
                                        value={$deviceTableDataStore.editDeviceData?.name}
                                        error={errors.device?.length > 0}
                                        helperText={errors.device?.length > 0 ? errors.device : false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                noOptionsText={"Организации не найдены"}
                                value={organisationSelected}
                                id="organisation"
                                options={fullOrgList}
                                disabled={isDetach}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        variant="outlined"
                                        placeholder="Организация"
                                        label="Организация"
                                        error={errors.organisation?.length > 0}
                                        helperText={errors.organisation?.length > 0 ? errors.organisation : false}
                                    />
                                )}
                                onChange={(e, value) => {
                                    handleOrganisationChange(value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                noOptionsText={"Сотрудники не найдены"}
                                id="user"
                                options={members !== undefined && (form.organisation !== "" || isDetach) ? members : []}
                                disabled={
                                    form.organisation === "" ||
                                    form.organisation === undefined ||
                                    isDetach ||
                                    membersloadingTable
                                }
                                value={userSelected}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        variant="outlined"
                                        placeholder="Сотрудник"
                                        label="Сотрудник"
                                        error={errors.user?.length > 0}
                                        helperText={errors.user?.length > 0 ? errors.user : false}
                                    />
                                )}
                                onChange={(e, value) => {
                                    handleUserChange(value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: 30 }}>
                            <DateTimePicker
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                label={isDetach ? "Дата завершения" : "Дата начала"}
                                slotProps={{
                                    field: {
                                        clearable: true,
                                    },
                                    textField: {
                                        error: errors.date?.length > 0,
                                        helperText: errors.date?.length > 0 ? errors.date : false,
                                        inputProps: {
                                            readOnly: true,
                                        },
                                        onClick: () => setOpen(true),
                                    },
                                }}
                                minDateTime={minDateTime}
                                onChange={(value) => value && setFormValue("date", value.format("DD.MM.YYYY HH:mm:ss"))}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                            <ButtonSquare
                                variant="contained"
                                color="primary"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: "15px auto",
                                }}
                                onClick={handleSubmit}
                            >
                                Сохранить
                            </ButtonSquare>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledDialog>
    );
});
export default Attach;
