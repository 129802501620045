import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { TimeProps } from "../../types/ui/datePicker";

const StyledTimePicker = styled(MuiTimePicker)({
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontSize: "16px",
    color: "#1d1d1b",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px",
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff",
        },
    },
});

const TimePicker = (props: TimeProps) => {
    const open = props.open;
    const onOpen = !!props.onOpen ? props.onOpen : () => {};
    const onClose = !!props.onClose ? props.onClose : () => {};
    const minTime = dayjs(props.minTime ?? "00:00:00");
    const maxTime = dayjs(props.maxTime ?? "23:59:59");
    const timezone = props.timezone ?? "system";
    const format = props.format ?? "HH:mm";
    const views = props.views ?? ["hours", "minutes"];
    const disabled = props.disabled ?? false;
    const value = props.value ?? null;
    const defaultValue = props.defaultValue ?? null;
    const label = props.label ?? "";
    const onChange = !!props.onChange ? props.onChange : (e: any, v: any) => {};
    const slotProps = !!props.slotProps ? props.slotProps : {};

    return (
        <LocalizationProvider adapterLocale="ru" dateAdapter={AdapterDayjs}>
            <StyledTimePicker
                value={value}
                defaultValue={defaultValue}
                label={label}
                disabled={disabled}
                timezone={timezone}
                views={views}
                format={format}
                minTime={minTime}
                maxTime={maxTime}
                slotProps={slotProps}
                onChange={onChange}
                open={open}
                onOpen={onOpen}
                onClose={onClose}
            />
        </LocalizationProvider>
    );
};

export default TimePicker;
