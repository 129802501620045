import { Grid, MenuItem, Typography, Button, TableContainer, Paper } from "@mui/material";
import { AddButton, SearchField } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";
import { editStatusContractHouse } from "../../../../../api-actions/ContractsApi";
import { $contractsDataStore } from "../../../../../store/ContractsStore";
import { $loginUserStore } from "../../../../../store/ResponseData";
import { AddressZone, ContractAddress } from "../../../../../types/contractAddress";
import { AppRoute, ContractRelationStatus } from "../../../../../utils/const";
import ConfirmDialog from "../../../../ui/dialog/ConfirmDialog";
import AddHouse from "./AddHouse";
import Table from "../../../../ui/table/Table";
import { type MRT_ColumnDef } from "material-react-table";
import { formatDate } from "../../../../../utils/utils";
import { generatePath, useNavigate } from "react-router-dom";
import { OpenInNew } from "@mui/icons-material";
import dayjs from "dayjs";
import DatePicker from "../../../../ui/DatePicker";

const ContractCardAddress = observer(() => {
    const [error, setError] = useState("");
    const [date, setDate] = useState("");
    const [submitIsDisable, setSubmitIsDisable] = useState(true);
    let navigate = useNavigate();

    const {
        contractAddressPage,
        contractAddressRowsPerPage,
        contractAddressSort,
        contractAddressSearch,
        houseList,
        contractDetail,
        contractAddressloadingTable,
        addressEditData,
    } = $contractsDataStore;

    const getEnv = (statusIn = null) => {
        const status = statusIn ?? addressEditData.status;
        if (status === "WORK") {
            return {
                title: "Архивировать адрес",
                menu: "Архивировать",
                submitText: "Архивировать",
                changeStatus: "EXCLUDED",
                message: "отправить в архив",
                pickerlabel: "Дата завершения",
                isWork: true,
                changeKey: "end",
            };
        } else {
            return {
                title: "Вернуть из архива",
                menu: "Вернуть из архива",
                submitText: "Вернуть",
                changeStatus: "WORK",
                message: "вернуть из архива",
                pickerlabel: "Дата начала",
                isWork: false,
                changeKey: "start",
            };
        }
    };
    useEffect(() => {
        $contractsDataStore.getHouseList(
            String(contractDetail?.guid),
            String(contractAddressPage + 1),
            contractAddressSort.by,
            contractAddressSort.order,
            String(contractAddressRowsPerPage),
            contractAddressSearch
        );
    }, [
        contractAddressPage,
        contractAddressRowsPerPage,
        contractAddressSort.by,
        contractAddressSort.order,
        contractDetail?.guid,
        contractAddressSearch,
    ]);

    const columns: MRT_ColumnDef<ContractAddress>[] = [
        {
            accessorKey: "id",
            header: "№ п/п",
            size: 100,
            enableSorting: false,
            Cell: ({ row }) => <div>{contractAddressRowsPerPage * contractAddressPage + row.index + 1}</div>,
        },
        {
            id: "address",
            accessorKey: "name",
            header: "Адрес обслуживания",
            size: 300,
        },
        {
            accessorKey: "zone",
            header: "Зона",
            size: 150,
            enableSorting: false,
            accessorFn: (row) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        {row.zones.items.length !== undefined ? (
                            row.zones.items.map((zone: AddressZone) => {
                                return (
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="text"
                                        tabIndex={-1}
                                        endIcon={
                                            <OpenInNew
                                                style={{
                                                    width: 12,
                                                    height: 12,
                                                }}
                                            />
                                        }
                                        style={{
                                            fontSize: 12,
                                            padding: 0,
                                        }}
                                        onClick={() => {
                                            navigate(
                                                generatePath(AppRoute.ZoneDetail, {
                                                    guid: String(zone.guid),
                                                })
                                            );
                                        }}
                                    >
                                        {zone.id}
                                    </Button>
                                );
                            })
                        ) : $loginUserStore.isAdmin() ? (
                            <Button
                                component="label"
                                role={undefined}
                                variant="text"
                                tabIndex={-1}
                                endIcon={
                                    <OpenInNew
                                        style={{
                                            width: 12,
                                            height: 12,
                                        }}
                                    />
                                }
                                style={{
                                    fontSize: 12,
                                    padding: 0,
                                    textTransform: "unset",
                                    color: "rgba(255, 0, 0, 0.75)",
                                }}
                                onClick={() => {
                                    navigate(
                                        generatePath(AppRoute.ZoneNew) +
                                            "?" +
                                            new URLSearchParams({
                                                house: row.house,
                                                // name: tableLine.name,
                                                backurl: generatePath(AppRoute.ContractDetail, {
                                                    guid: row.contract,
                                                }),
                                            }).toString()
                                    );
                                }}
                            >
                                Создать
                            </Button>
                        ) : null}
                    </div>
                );
            },
        },
        {
            id: "start",
            accessorFn: (row) => formatDate(row.start, "d.m.y"),
            header: "Начало обслуживания",
            size: 200,
            enableSorting: false,
        },
        {
            id: "end",
            accessorFn: (row) => formatDate(row.end, "d.m.y"),
            header: "Окончание обслуживания",
            size: 200,
            enableSorting: false,
        },
        {
            accessorKey: "status",
            accessorFn: (row) =>
                row?.status ? ContractRelationStatus[row?.status as keyof typeof ContractRelationStatus] : null,
            header: "Статус",
            size: 150,
        },
    ];

    const changeTableSort = useCallback(
        (key: string) => {
            $contractsDataStore.setAddressSort({
                by: key,
                order: contractAddressSort.by !== key ? "asc" : contractAddressSort.order === "asc" ? "desc" : "asc",
            });
        },
        [contractAddressSort]
    );

    const handleChangePage = (v: number) => {
        $contractsDataStore.setAddressPage(v);
    };

    const handleChangeRowsPerPage = (v: number) => {
        $contractsDataStore.setAddressRowsPerPage(v);
    };

    const handleAddAddress = () => {
        $contractsDataStore.setAddNewHouse(true);
    };

    const handleChangeStatus = async (form: ContractAddress, status: string) => {
        let textError = "";
        if (!date) {
            textError = "Дата обязательна!";
        }

        if (textError) {
            setError(textError);
            setSubmitIsDisable(true);
            return;
        }

        setLoading(true);

        (form as any).status = status;
        $contractsDataStore.setLoading(true);
        await editStatusContractHouse(form).then((contract) => {
            $contractsDataStore.setAddressEdit(false);
            $contractsDataStore.getHouseList(
                String(contractDetail?.guid),
                String(contractAddressPage + 1),
                contractAddressSort.by,
                contractAddressSort.order,
                String(contractAddressRowsPerPage),
                contractAddressSearch
            );
            $contractsDataStore.setAddNewHouse(false);
        });
        $contractsDataStore.setAddressLoading(false);
        setLoading(false);
    };

    const [loading, setLoading] = useState<boolean>(false);

    // нормализуем даты для корректного сравнения, у одних дат есть время, у других только дата, приводим к единому формату
    const normalizeDate = (dateString: string) => {
        const normDate = dayjs(dateString).format("YYYY-MM-DD");
        return new Date(normDate).getTime();
    };

    useEffect(() => {
        let textError = "";

        if (contractDetail?.start && date) {
            const contractDateStart = normalizeDate(contractDetail?.start);
            const currentdate = normalizeDate(date);

            if (contractDateStart > currentdate) {
                textError =
                    "Дата не может быть раньше даты начала действия договора (" +
                    dayjs(contractDetail?.start).format("DD.MM.YYYY") +
                    ")";
            }
        }

        if (contractDetail?.end && date) {
            const contractDateEnd = normalizeDate(contractDetail?.end);
            const currentdate = normalizeDate(date);

            if (contractDateEnd < currentdate) {
                textError =
                    "Дата не может быть позже даты окончания действия договора (" +
                    dayjs(contractDetail?.end).format("DD.MM.YYYY") +
                    ")";
            }
        }

        if (getEnv().isWork && addressEditData.start && date) {
            const start = normalizeDate(addressEditData.start);
            const currentdate = normalizeDate(date);

            if (start > currentdate) {
                textError =
                    "Дата архивирования не может быть меньше даты начала обслуживания дома (" +
                    dayjs(addressEditData.start).format("DD.MM.YYYY") +
                    ")";
            }
        }

        if (!getEnv().isWork && addressEditData.end && date) {
            const end = normalizeDate(addressEditData.end);
            const currentdate = normalizeDate(date);

            if (end > currentdate) {
                textError =
                    "Дата поднятия из архива не может быть меньше даты завершения обслуживания дома (" +
                    dayjs(addressEditData.end).format("DD.MM.YYYY") +
                    ")";
            }
        }

        setError(textError);
        if (textError || !date) {
            setSubmitIsDisable(true);
        } else {
            setSubmitIsDisable(false);
        }
    }, [date]);

    return (
        <>
            <Grid container justifyContent={$loginUserStore.isAdmin() ? "space-between" : "flex-end"}>
                {$loginUserStore.isAdmin() ? (
                    <AddButton
                        onClick={handleAddAddress}
                        disabled={contractDetail?.available_houses?.items.length === undefined}
                        style={{
                            margin: 0,
                        }}
                    />
                ) : null}
                <SearchField
                    value={contractAddressSearch}
                    callback={(value) => $contractsDataStore.setAddressSearch(value)}
                    callbackTimeout={700}
                    style={{
                        width: 300,
                    }}
                />
            </Grid>
            <TableContainer
                component={Paper}
                style={{
                    margin: "15px 0 0",
                }}
            >
                <Table
                    data={houseList?.items}
                    columns={columns}
                    loading={contractAddressloadingTable}
                    sortProps={contractAddressSort}
                    pageIndex={contractAddressPage}
                    pageSize={contractAddressRowsPerPage}
                    changeTableSort={changeTableSort}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowCount={houseList?.pagination.total! ?? 0}
                    enableRowActions={$loginUserStore.isAdmin()}
                    renderRowActionMenuItems={({ closeMenu, row }) => [
                        <MenuItem
                            onClick={() => {
                                $contractsDataStore.setAddressEditData(row.original);
                                $contractsDataStore.setAddressEdit(true);
                                closeMenu();
                            }}
                        >
                            {getEnv(row.original.status).menu}
                        </MenuItem>,
                    ]}
                />
            </TableContainer>
            <ConfirmDialog
                display={$contractsDataStore.addressEdit}
                title={getEnv().title}
                submitText={getEnv().submitText}
                onClose={(event) => $contractsDataStore.setAddressEdit(false)}
                onSubmit={(event) => {
                    handleChangeStatus(addressEditData, getEnv().changeStatus);
                }}
                loading={loading}
                submitIsDisable={submitIsDisable}
            >
                <Grid container spacing={3} justifyContent={"center"} mb={3}>
                    <Grid item>
                        <Typography variant="subtitle1">
                            Вы уверены, что хотите {getEnv().message} адрес <b>{addressEditData?.name}</b> для текущего
                            договора?
                        </Typography>
                    </Grid>
                    <Grid item>
                        <DatePicker
                            value={date ? dayjs(date) : null}
                            label={getEnv().pickerlabel}
                            slotProps={{
                                textField: {
                                    clearable: true,
                                    error: !!error,
                                    helperText: error,
                                    sx: {
                                        width: "208px",
                                    },
                                },
                            }}
                            onChange={(value) => {
                                const newDate =
                                    value && dayjs(value).isValid() ? dayjs(value).format("YYYY-MM-DD") : "";
                                setDate(newDate);
                                $contractsDataStore.setAddressEditData({
                                    ...addressEditData,
                                    [getEnv().changeKey]: newDate,
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </ConfirmDialog>
            {$contractsDataStore.addNewHouse && <AddHouse />}
        </>
    );
});
export default ContractCardAddress;
