import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { DateTimeProps } from "../../types/ui/datePicker";

const StyledDateTimePicker = styled(MuiDateTimePicker)({
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontSize: "16px",
    color: "#1d1d1b",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px",
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff",
        },
    },
});

const DateTimePicker = (props: DateTimeProps) => {
    const open = props.open;
    const onOpen = !!props.onOpen ? props.onOpen : () => {};
    const onClose = !!props.onClose ? props.onClose : () => {};
    const minDateTime = dayjs(props.minDateTime ?? "1901-01-01");
    const maxDateTime = dayjs(props.maxDateTime ?? "2100-01-01");
    const timezone = props.timezone ?? "system";
    const format = props.format ?? "DD.MM.YYYY HH:mm:ss";
    const views = props.views ?? ["year", "month", "day", "hours", "minutes"];
    const disabled = props.disabled ?? false;
    const value = props.value ?? null;
    const defaultValue = props.defaultValue ?? null;
    const label = props.label ?? "";
    const onChange = !!props.onChange ? props.onChange : (e: any, v: any) => {};
    const slotProps = !!props.slotProps ? props.slotProps : {};

    return (
        <LocalizationProvider adapterLocale="ru" dateAdapter={AdapterDayjs}>
            <StyledDateTimePicker
                value={value}
                defaultValue={defaultValue}
                label={label}
                disabled={disabled}
                timezone={timezone}
                views={views}
                format={format}
                minDateTime={minDateTime}
                maxDateTime={maxDateTime}
                slotProps={slotProps}
                onChange={onChange}
                open={open}
                onOpen={onOpen}
                onClose={onClose}
            />
        </LocalizationProvider>
    );
};

export default DateTimePicker;
