import { Grid, TextField, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ButtonSquare } from "@synapse/frontend-react";
import throttle from "lodash.throttle";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { getHouseListInfo } from "../../../api-actions/api-actions";
import { StyledCard } from "../../../App";
import { CustomCircularProgress } from "../../ui/CustomCircularProgress";
import CustomMap from "../../ui/map/Map";
import { $houseListStore } from "../../../store/HouseListData";
import { $loginUserStore } from "../../../store/ResponseData";
import { $zoneTableDataStore } from "../../../store/ZoneTableStore";
import { NewZoneDetails } from "../../../types/newZoneDetails";
import { Item } from "../../../types/ui/autocomplete";
import MainContentWrap from "../../ui/wrappers/MainContentWrap";
import { AppRoute } from "../../../utils/const";
import { getUKName } from "../../../utils/utils";
import { saveZone } from "./ZoneListApi";
import Autocomplete from "../../ui/Autocomplete";

const AddNewZone = observer(() => {
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const defaultBackUrl = generatePath(AppRoute.ZoneList);
    const [backurl, setBackurl] = useState(defaultBackUrl);
    const [showButtons, setShowButtons] = useState(false);
    const [saving, setSaving] = useState(false);
    const [form, setForm] = useState<NewZoneDetails>({
        id: "",
        coordinates: undefined,
        description: "",
        house: "",
        uk: "",
        guid: "",
        name: "",
        loaded: false,
    });

    if (!$loginUserStore.isAdmin()) {
        navigate(AppRoute.ZoneList);
    }

    const { zoneCoordinates } = $zoneTableDataStore;

    const setFormValue = useCallback(
        (name: string, value: string) => {
            setForm({ ...form, [name]: value });
        },
        [form]
    );

    const [zoneSelected, setZoneSelected] = useState<Item>({
        id: "",
        label: "",
    });

    const { data } = $houseListStore;

    const addresses = useMemo(() => {
        return data?.items.map((item) => {
            return {
                id: item.guid,
                label: item.address,
            };
        });
    }, [data?.items]);

    const handleFormChange = useCallback(
        (value: any) => {
            const currentItem = data?.items.find((item) => {
                return item.guid === value?.id;
            });

            if (currentItem) {
                setZoneSelected(value);
                setForm({
                    ...form,
                    uk: currentItem?.uk!,
                    name: currentItem?.address as string,
                    house: currentItem?.guid!,
                    loaded: true,
                });

                $zoneTableDataStore.setNewCoordinates(currentItem?.point!);
            } else {
                setZoneSelected({
                    id: "",
                    label: "",
                });
                setForm({
                    ...form,
                    loaded: false,
                });
            }
        },
        [data?.items, form]
    );

    useEffect(() => {
        setBackurl(searchParams.get("backurl") ?? defaultBackUrl);
        const curHouse = searchParams.get("house") as string;
        if (curHouse !== null && $houseListStore.houseSearch !== curHouse) {
            $houseListStore.setSearch(curHouse);

            const getHouseList = async () => {
                return await getHouseListInfo(curHouse);
            };
            const throttledGetHouseList = throttle(getHouseList, 100);
            throttledGetHouseList()?.then((res) => {
                if (res) {
                    $houseListStore.setData(res);

                    const currentItem = res.items.find((item) => {
                        return item.guid === curHouse;
                    });

                    if (typeof currentItem !== "undefined") {
                        setZoneSelected({ id: currentItem?.guid, label: currentItem?.address as string });

                        setForm({
                            ...form,
                            uk: currentItem?.uk!,
                            name: currentItem?.address as string,
                            house: currentItem?.guid!,
                            loaded: true,
                        });

                        $zoneTableDataStore.setNewCoordinates(currentItem?.point!);
                    }
                }
            });
        }
    }, [searchParams]);

    const handleClick = () => {
        $zoneTableDataStore.setShowZoneDetailes(false);
        navigate(generatePath(AppRoute.ZoneList));
    };

    useEffect(() => {
        setFormValue("coordinates", JSON.stringify(zoneCoordinates));
    }, [zoneCoordinates]);

    useEffect(() => {
        if (form.house && zoneCoordinates) {
            setShowButtons(true);
        } else {
            setShowButtons(false);
        }
    }, [form.house, zoneCoordinates]);

    // при изменении адреса сбрасываем сохраненные координаты зоны
    useEffect(() => {
        $zoneTableDataStore.setCoordinates(undefined);
    }, [form.house]);

    const handleSubmit = async () => {
        setSaving(true);
        $zoneTableDataStore.setLoadingZoneTable(true);
        await saveZone(form).then((zone) => {
            if (zone) {
                navigate(backurl);
                setSaving(false);
            }
        });
        $zoneTableDataStore.setLoadingZoneTable(false);
    };

    return (
        <MainContentWrap>
            <>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    style={{
                        paddingBottom: "1em",
                        paddingLeft: ".75em",
                    }}
                >
                    <Grid item>
                        <Grid container direction={"row"} spacing={2} alignItems={"baseline"}>
                            <Grid item>
                                <h3>Создание новой зоны</h3>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <StyledCard>
                    <Grid container direction={"row"} justifyContent={"space-evenly"} spacing={3}>
                        <Grid item xs={8} lg={8} sm={7} md={8}>
                            {saving || (!form.loaded && backurl !== defaultBackUrl) ? (
                                <div
                                    style={{
                                        height: 700,
                                        border: "1px solid #5ac8fa",
                                    }}
                                >
                                    <CustomCircularProgress />
                                </div>
                            ) : form.loaded ? (
                                <CustomMap
                                    style={{
                                        height: 700,
                                        border: "1px solid #5ac8fa",
                                    }}
                                />
                            ) : (
                                <div
                                    style={{
                                        height: 700,
                                        margin: "auto",
                                        border: "1px solid #5ac8fa",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    Не выбран адрес
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={4} lg={4} sm={5} md={4}>
                            <StyledCard
                                style={{
                                    height: "auto",
                                }}
                            >
                                <Grid
                                    container
                                    direction={"column"}
                                    spacing={3}
                                    justifyContent={"space-between"}
                                    style={{
                                        maxWidth: 360,
                                        maxHeight: "75vh",
                                    }}
                                >
                                    <Grid item>
                                        <Autocomplete
                                            noOptionsText="Адрес не найден"
                                            id="name"
                                            value={zoneSelected}
                                            options={addresses}
                                            renderInput={(params) => (
                                                <Tooltip title={form.name}>
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Адрес"
                                                        label="Адрес"
                                                    />
                                                </Tooltip>
                                            )}
                                            disabled={backurl !== defaultBackUrl}
                                            onChange={(e, value) => {
                                                handleFormChange(value);
                                            }}
                                            onInputChange={(e, value) => {
                                                if (backurl === defaultBackUrl) {
                                                    $houseListStore.getData(value);
                                                }
                                            }}
                                            sx={{
                                                ".MuiOutlinedInput-notchedOutline": {
                                                    border: "1px solid #5ac8fa",
                                                    borderRadius: "12px",
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#5ac8fa",
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            placeholder="Управляющая компания"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            size="medium"
                                            variant="outlined"
                                            fullWidth
                                            id="uk"
                                            name="uk"
                                            value={form.uk === "" ? "" : getUKName(form.uk)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            placeholder="Описание"
                                            label="Описание"
                                            size="medium"
                                            variant="outlined"
                                            fullWidth
                                            name="description"
                                            id="description"
                                            value={form.description}
                                            onChange={(value) => setFormValue("description", value.target.value)}
                                        />
                                    </Grid>
                                    {showButtons && (
                                        <Grid
                                            container
                                            item
                                            direction={"row"}
                                            spacing={2}
                                            justifyContent={"space-between"}
                                        >
                                            <Grid item>
                                                <ButtonSquare
                                                    variant="contained"
                                                    onClick={handleSubmit}
                                                    disabled={saving}
                                                >
                                                    Создать
                                                </ButtonSquare>
                                            </Grid>
                                            <Grid item>
                                                <ButtonSquare
                                                    variant="outlined"
                                                    onClick={handleClick}
                                                    disabled={saving}
                                                >
                                                    Отмена
                                                </ButtonSquare>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </StyledCard>
                        </Grid>
                    </Grid>
                </StyledCard>
            </>
        </MainContentWrap>
    );
});

export default AddNewZone;
