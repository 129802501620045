import {
    Grid,
    Tab,
    Tabs,
    TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react';
import {
    useEffect,
    useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { StyledCard } from '../../../App';
import CompletedWorks from './contractCards/completedWorks/CompletedWorks';
import ContractCardAddress from './contractCards/address/ContractCardAddress';
import ContractCardMembers from './contractCards/members/ContractCardMembers';
import ContractReport from './contractCards/report/ContractReport';
import NormativeData from './contractCards/normativeData/NormativeData';
import { $contractsDataStore } from '../../../store/ContractsStore';
import { $loginUserStore } from '../../../store/ResponseData';
import MainContentWrap from '../../ui/wrappers/MainContentWrap';
import {
    getOrganisationName,
    getUKName,
} from '../../../utils/utils';

const StyledTab = styled(Tab)({
    "&.Mui-selected": {
        background: "#20a0ff",
        fontWeight: 600,
        color: "#fff",
        height: 48,
        borderRadius: "8px 8px 0 0",
    },
    minWidth: "100px",
    maxWidth: "100%",
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "0.5px",
    textTransform: "initial",
    fontWeight: "400",
    marginLeft: "10px",
    backgroundColor: "#e0e0e0",
    borderRadius: "8px 8px 0 0",
});

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

const ContractDetail = observer(() => {
    const routeParams = useParams();
    const {
        contractDetail,
    } = $contractsDataStore;

    const [tab, setTab] = useState(0);

    useEffect(() => {
        $contractsDataStore.getDetail(
            String(routeParams.guid)
        );
    }, [
        routeParams.guid,
    ]);

    const contractName = $contractsDataStore.getName(contractDetail);
    const contractFrom = contractDetail?.from ? getUKName(contractDetail?.from) : ' ';
    const contractTo = contractDetail?.to ? getOrganisationName(contractDetail?.to) : ' ';
    const contractDescription = contractDetail?.description ? contractDetail?.description : ' ';

    const changeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const {administrator, ukWorker, poWorker, headRegion} = $loginUserStore   

    const pageTabs = [
        {
            name: 'Адреса',
            component: <ContractCardAddress />,
            display: administrator || ukWorker || poWorker || headRegion,
        },
        {
            name: 'Сотрудники',
            component: <ContractCardMembers />,
            display: administrator || ukWorker || poWorker || headRegion,
        },
        {
            name: 'Отчёт',
            component: <ContractReport />,
            display: administrator || ukWorker || poWorker || headRegion,
        },
        {
            name: 'Акт вып. работ',
            component: <CompletedWorks />,
            display: administrator || ukWorker || poWorker || headRegion,
        },
        {
            name: 'Норматив',
            component: <NormativeData />,
            display: administrator || ukWorker || poWorker || headRegion,
        },
    ];

    return (
        <MainContentWrap>
            <>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    style={{
                        paddingBottom: '1em',
                        paddingLeft: '.75em',
                    }}
                >
                    <Grid item>
                        <Grid
                            container
                            direction={"row"}
                            spacing={2}
                            alignItems={"baseline"}
                        >
                            <Grid item>
                                <h3>Договор №{contractName}</h3>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction={"row"} spacing={2} justifyContent={"center"} mb={1.2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Управляющая компания"
                            InputProps={{
                                readOnly: true,
                            }}
                            size="medium"
                            variant="outlined"
                            fullWidth
                            value={contractFrom}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Организация"
                            InputProps={{
                                readOnly: true,
                            }}
                            size="medium"
                            variant="outlined"
                            fullWidth
                            value={contractTo}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Номер договора"
                            InputProps={{
                                readOnly: true,
                            }}
                            size="medium"
                            variant="outlined"
                            fullWidth
                            value={contractName}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Описание"
                            InputProps={{
                                readOnly: true,
                            }}
                            size="medium"
                            variant="outlined"
                            fullWidth
                            value={contractDescription}
                        />
                    </Grid>
                </Grid>
                <Tabs
                    value={tab}
                    TabIndicatorProps={{
                        style: {
                            display: "none",
                        },
                    }}
                    onChange={changeTab}
                >
                    {pageTabs.map((item, i) => {
                        if (item.display) {
                            return (
                                <StyledTab
                                    label={item.name}
                                    value={i}
                                />
                            );
                        } else {
                            return null
                        }
                    })}
                </Tabs>
                <StyledCard>
                    {pageTabs.map((item, i) => {
                        if (item.display) {
                            return (
                                <TabPanel value={tab} index={i} key={i}>
                                    {item.component}
                                </TabPanel>
                            );
                        }
                        return <></>;
                    })}
                </StyledCard>
            </>
        </MainContentWrap>
    );
});
export default ContractDetail;
