import { AddCircleOutline } from "@mui/icons-material";
import { Grid, IconButton, MenuItem } from "@mui/material";
import { SearchField } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import { StyledCard } from "../../../App";
import AddContract from "./AddContract";
import { $contractsDataStore } from "../../../store/ContractsStore";
import { $loginUserStore } from "../../../store/ResponseData";
import MainContentWrap from "../../ui/wrappers/MainContentWrap";
import Table from "../../ui/table/Table";
import { getUKName, getOrganisationName, formatDate } from "../../../utils/utils";
import { type MRT_ColumnDef } from "material-react-table";
import { AppRoute, ContractStatus } from "../../../utils/const";
import { ContractInfo } from "../../../types/contractListInfo";
import { generatePath, useNavigate } from "react-router-dom";

const Contracts = observer(() => {
    let navigate = useNavigate();
    const {
        contractsPage,
        contractsRowsPerPage,
        contractsloadingTable,
        contractsSort,
        contractsSearch,
        contractsData,
    } = $contractsDataStore;

    useEffect(() => {
        $contractsDataStore.getData(
            String(contractsPage + 1),
            contractsSort.by,
            contractsSort.order,
            String(contractsRowsPerPage),
            contractsSearch
        );
    }, [contractsSearch, contractsPage, contractsRowsPerPage, contractsSort]);

    const columns: MRT_ColumnDef<ContractInfo>[] = [
        {
            id: "from",
            accessorFn: (row) => getUKName(row.from),
            header: "Управляющая компания",
            size: 150,
        },
        {
            id: "to",
            accessorFn: (row) => getOrganisationName(row.to),
            header: "Организация",
            size: 150,
        },
        {
            accessorKey: "number",
            header: "№ договора",
            size: 150,
        },
        {
            accessorFn: (row) => formatDate(row.start, "d.m.y"),
            header: "Начало действия",
            size: 121,
            enableSorting: false,
        },
        {
            accessorFn: (row) => formatDate(row.end, "d.m.y"),
            header: "Окончание действия",
            size: 150,
            enableSorting: false,
        },
        {
            accessorKey: "description",
            header: "Описание",
            size: 150,
            enableSorting: false,
        },
        {
            id: "status",
            accessorFn: (row) => (row?.status ? ContractStatus[row?.status as keyof typeof ContractStatus] : null),
            header: "Статус",
            size: 100,
            enableSorting: false,
        },
    ];

    const changeTableSort = useCallback(
        (key: string) => {
            $contractsDataStore.setSort({
                by: key,
                order: contractsSort.by !== key ? "asc" : contractsSort.order === "asc" ? "desc" : "asc",
            });
        },
        [contractsSort]
    );

    const handleChangePage = (v: number) => {
        $contractsDataStore.setPage(v);
    };

    const handleChangeRowsPerPage = (v: number) => {
        $contractsDataStore.setRowsPerPage(v);
        $contractsDataStore.setPage(0);
    };

    const handleClickRow = useCallback(
        (data: ContractInfo) => {
            navigate(
                generatePath(AppRoute.ContractDetail, {
                    guid: String(data.guid),
                })
            );
        },
        [navigate]
    );

    return (
        <MainContentWrap>
            <>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    style={{
                        paddingBottom: "1em",
                        paddingLeft: ".75em",
                    }}
                >
                    <Grid item>
                        <Grid container direction={"row"} spacing={2} alignItems={"baseline"}>
                            <Grid item>
                                <h3>Подрядчики и договоры</h3>
                            </Grid>
                            {$loginUserStore.isAdmin() ? (
                                <Grid item>
                                    <IconButton onClick={() => $contractsDataStore.setOpenEditContract(true)}>
                                        <AddCircleOutline />
                                    </IconButton>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <SearchField
                            value={contractsSearch}
                            callback={(value) => $contractsDataStore.setSearch(value)}
                            callbackTimeout={700}
                            style={{
                                width: 300,
                            }}
                        />
                    </Grid>
                </Grid>
                <StyledCard>
                    <Table
                        data={contractsData?.items}
                        columns={columns}
                        loading={contractsloadingTable}
                        sortProps={contractsSort}
                        pageIndex={contractsPage}
                        pageSize={contractsRowsPerPage}
                        changeTableSort={changeTableSort}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        rowCount={contractsData?.pagination.total! ?? 0}
                        handleClickRow={handleClickRow}
                        enableRowActions={$loginUserStore.isAdmin()}
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                                onClick={() => {
                                    $contractsDataStore.setEditContractData(row.original);
                                    $contractsDataStore.setOpenEditContract(true);
                                    closeMenu()
                                }}
                            >
                                Редактировать
                            </MenuItem>,
                        ]}
                    />
                    {$contractsDataStore.openEditContract && <AddContract />}
                </StyledCard>
            </>
        </MainContentWrap>
    );
});
export default Contracts;
