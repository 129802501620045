import {
    Dialog,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    Box,
    IconButton,
    MenuItem,
    DialogContent,
    Alert,
} from "@mui/material";
import { ButtonSquare, ExportButton } from "@synapse/frontend-react";
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import axios from "../../../../../axios";
import { $contractsDataStore } from "../../../../../store/ContractsStore";
import Notification from "../../../../ui/Notification";
import DatePicker from "../../../../ui/DatePicker";
import { StyledCard } from "../../../../../App";
import Table from "../../../../ui/table/Table";
import { formatDate, formatDateTime } from "../../../../../utils/utils";
import { type MRT_ColumnDef } from "material-react-table";
import { ServiceTime } from "../../../../../types/serviceTimes";
import { AddCircleOutline } from "@mui/icons-material";
import { $loginUserStore } from "../../../../../store/ResponseData";
import EditServiceTime from "./EditServiceTime";
import { DEFAULT_SERVICE_TIME } from "../../../../../utils/const";
import { deleteServiceTime } from "../../../../../api-actions/ContractsApi";
import ProgressBar from "../../../../ui/ProgressBar";
import { styled } from "@mui/material/styles";

const StyledDialog = styled(Dialog)({
    height: "auto",
    padding: "15px",
    margin: "0 auto",
});

const ContractReport = observer(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const [loadingUploads, setLoadingUploads] = useState(false);
    const [loadingMail, setLoadingMail] = useState(false);
    const [dates, setDates] = useState({
        start: dayjs(yesterday).startOf("month"),
        end: dayjs(yesterday),
    });

    const [mailDialogOpen, setMailDialogOpen] = useState(false);
    const [rmServiceTimeOpen, setRmServiceTimeOpen] = useState(false);
    const [mail, setMail] = useState("");
    const [errors, setErrors] = useState({
        start: "",
        end: "",
    });
    const [showTable, setShowTable] = useState(false);
    const [progressOpen, setProgressOpen] = useState(false);
    const [cacheKey, setCacheKey] = useState("");

    const {
        contractDetail,
        serviceTimeListShort,
        serviceTimeListLong,
        serviceTimeLoadingShort,
        serviceTimeLoadingLong,
        serviceTimePage,
        serviceTimeSort,
        serviceTimeRowsPerPage,
        editServiceTimeData,
    } = $contractsDataStore;

    const handleDates = (name: string, value: Dayjs) => {
        setDates({ ...dates, [name]: value });
    };

    useEffect(() => {
        $contractsDataStore.getServiceTimeList(
            String(contractDetail?.guid),
            String(serviceTimePage + 1),
            serviceTimeSort.by,
            serviceTimeSort.order,
            String(serviceTimeRowsPerPage)
        );
    }, []);

    useEffect(() => {
        $contractsDataStore.getServiceTimeList(
            String(contractDetail?.guid),
            String(serviceTimePage + 1),
            serviceTimeSort.by,
            serviceTimeSort.order,
            String(serviceTimeRowsPerPage),
            true
        );
    }, [serviceTimePage, serviceTimeRowsPerPage, serviceTimeSort]);

    const columns: MRT_ColumnDef<ServiceTime>[] = [
        {
            accessorKey: "start_period",
            header: "Действует с",
            accessorFn: (row) => formatDate(row.start_period, "d.m.y"),
        },
        {
            header: "Период",
            accessorFn: (row) => {
                const currentDate = new Date().toISOString().split("T")[0];
                return (
                    formatDateTime(`${currentDate}T${row.start}`, "T", "H:i") +
                    " - " +
                    formatDateTime(`${currentDate}T${row.end}`, "T", "H:i")
                );
            },
            enableSorting: false,
        },
    ];

    useEffect(() => {
        checkForErrors();
    }, [dates]);

    const checkForErrors = () => {
        // Проверка на ошибки
        let startTextError = "";
        let endTextError = "";

        // Проверка: если даты отсутствуют
        if (!dates.start.isValid()) {
            startTextError = "Некорректно заполненное поле";
        }
        if (!dates.end.isValid()) {
            endTextError = "Некорректно заполненное поле";
        }

        if (dates.start.isValid() && dates.end.isValid()) {
            const startUnix = dates.start.unix();
            const endUnix = dates.end.unix();

            if (startUnix > endUnix) {
                startTextError = "Дата начала не может быть позже даты окончания";
                endTextError = "Дата окончания не может быть раньше даты начала";
            }

            //  Количество миллисекунд в одном дне
            const secondsInDay = 60 * 60 * 24;

            // Проверка, чтобы разница не превышала 120 дней
            const differenceInDays = (endUnix - startUnix) / secondsInDay;

            if (differenceInDays > 120) {
                startTextError = "Разница между датами превышает 120 дней";
                endTextError = "Разница между датами превышает 120 дней";
            }

            if (contractDetail?.start) {
                const startContractUnix = dayjs(contractDetail.start).unix();
                if (startUnix < startContractUnix) {
                    startTextError = "Дата начала не может быть меньше даты начала договора";
                }

                if (endUnix < startContractUnix) {
                    endTextError = "Дата окончания не может быть меньше даты начала договора";
                }
            }

            if (contractDetail?.end) {
                const endContractUnix = dayjs(contractDetail.end).unix();
                if (startUnix > endContractUnix) {
                    startTextError = "Дата начала не может быть больше даты окончания договора";
                }

                if (endUnix > endContractUnix) {
                    endTextError = "Дата окончания не может быть больше даты окончания договора";
                }
            }
        }

        if (startTextError || endTextError) {
            const fieldsErrors = { start: startTextError, end: endTextError };
            setErrors(fieldsErrors);
            return true;
        } else {
            setErrors({ start: "", end: "" });
            return false;
        }
    };

    const generateReport = async () => {
        if (checkForErrors()) return;
        setLoadingUploads(true);

        const data = {
            contract: contractDetail?.guid,
            start: dates.start.format("YYYY-MM-DD"),
            end: dates.end.format("YYYY-MM-DD"),
        };

        let config = {
            method: "post",
            url: "/GeoService/Reporting_zonesreport",
            data,
        };

        try {
            const response = await axios.request(config);
            const data = response?.data?.data;
            setCacheKey(data.cache_key);
            setProgressOpen(true);
        } catch (error: any) {
            Notification({
                title: "Не удалось запустить формирование отчёта",
                type: "error",
            });
        }
        setLoadingUploads(false);
    };

    const getReport = async () => {
        setLoadingUploads(true);

        const data = {
            cache_key: cacheKey,
        };

        let config = {
            method: "post",
            url: "/GeoService/Reporting_getReport",
            data,
        };

        try {
            const response = await axios.request(config);
            const data = response?.data?.data;
            require("downloadjs")(atob(data.content), data.name, data.content_type);
            setTimeout(() => {
                setProgressOpen(false);
            }, 1000);
        } catch (error: any) {
            Notification({
                title: "Не удалось выгрузить отчёт",
                type: "error",
            });
        }
        setLoadingUploads(false);
    };

    const mailReport = () => {
        if (checkForErrors()) return;
        setLoadingMail(true);

        // Регулярное выражение для проверки email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Проверка, соответствует ли переданный email регулярному выражению
        if (!emailRegex.test(mail)) {
            Notification({
                title: "Неверный формат email",
                type: "error",
            });
            setLoadingMail(false);
            return;
        }

        const request = {
            contract: contractDetail?.guid,
            start: dates.start.format("YYYY-MM-DD"),
            end: dates.end.format("YYYY-MM-DD"),
            mail,
        };

        let config = {
            method: "post",
            url: "/GeoService/Reporting_mailZonesReport",
            data: request,
        };

        Notification({
            title: "Отчет отправляется. После отправки письма вы получите дополнительное уведомление",
            type: "success",
        });
        axios
            .request(config)
            .then(() => {
                Notification({
                    title: "Отчет отправлен на почту " + request.mail,
                    type: "success",
                });
            })
            .catch((error) => {
                Notification({
                    title: "Не удалось отправить отчет на почту",
                    type: "error",
                });
            });
        setMailDialogOpen(false);
        setLoadingMail(false);
    };

    const changeTableSort = (key: string) => {
        $contractsDataStore.setServiceTimeSort({
            by: key,
            order: serviceTimeSort.by !== key ? "asc" : serviceTimeSort.order === "asc" ? "desc" : "asc",
        });
    };

    const handleChangePage = (v: number) => {
        $contractsDataStore.setServiceTimePage(v);
    };

    const handleChangeRowsPerPage = (v: number) => {
        $contractsDataStore.setServiceTimeRowsPerPage(v);
        $contractsDataStore.setServiceTimePage(0);
    };

    const closeServiceTimeDialog = () => {
        $contractsDataStore.setEditServiceTimeData(DEFAULT_SERVICE_TIME);
        setRmServiceTimeOpen(false);
    };

    const rmServiceTime = async () => {
        $contractsDataStore.setLoading(true);
        await deleteServiceTime(editServiceTimeData).then(() => {
            $contractsDataStore.getServiceTimeList(
                String(contractDetail?.guid),
                String(serviceTimePage + 1),
                serviceTimeSort.by,
                serviceTimeSort.order,
                String(serviceTimeRowsPerPage)
            );
        });

        $contractsDataStore.setEditServiceTimeData(DEFAULT_SERVICE_TIME);
        setRmServiceTimeOpen(false);
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={5} container direction={"column"} spacing={1}>
                    <Grid item container spacing={1}>
                        <Grid item xs={6}>
                            <DatePicker
                                defaultValue={dates.start}
                                label="Дата с"
                                minDate={"2024-07-01"}
                                maxDate={yesterday}
                                slotProps={{
                                    textField: {
                                        clearable: false,
                                        error: !!errors.start,
                                        helperText: errors.start,
                                        fullWidth: true,
                                    },
                                }}
                                onChange={(value) => {
                                    value && handleDates("start", dayjs(value));
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                defaultValue={dates.end}
                                label="Дата по"
                                minDate={"2024-07-01"}
                                maxDate={yesterday}
                                slotProps={{
                                    textField: {
                                        clearable: false,
                                        error: !!errors.end,
                                        helperText: errors.end,
                                        fullWidth: true,
                                    },
                                }}
                                onChange={(value) => {
                                    value && handleDates("end", dayjs(value));
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={6} container justifyContent={"center"}>
                            <ExportButton
                                variant="text"
                                onClick={generateReport}
                                loading={loadingUploads}
                                disabled={loadingUploads}
                            />
                        </Grid>
                        <Grid item xs={6} container justifyContent={"center"}>
                            <ExportButton
                                variant="text"
                                onClick={() => !checkForErrors() && setMailDialogOpen(true)}
                                loading={loadingMail}
                                disabled={loadingMail}
                            >
                                Отправить на почту
                            </ExportButton>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Alert severity="error">
                            Если не указано время оказания услуг, то по умолчанию расчет показателей будет произведен с
                            00:00 до 23:59
                        </Alert>
                    </Grid>
                    <Grid item>
                        <StyledCard>
                            <Grid container direction={"column"} spacing={2}>
                                <Grid item container>
                                    <Grid item flexGrow={1}>
                                        <Typography component="div">
                                            <Box
                                                sx={{
                                                    color: "#20a0ff",
                                                    textAlign: "center",
                                                    m: 1,
                                                    fontWeight: "medium",
                                                }}
                                            >
                                                Время оказания услуг
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    {$loginUserStore.isAdmin() ? (
                                        <Grid item>
                                            <IconButton onClick={() => $contractsDataStore.setEditServiceTime(true)}>
                                                <AddCircleOutline />
                                            </IconButton>
                                        </Grid>
                                    ) : null}
                                </Grid>
                                <Grid item>
                                    <Table
                                        data={serviceTimeListShort?.items.slice(0, 3)}
                                        columns={columns}
                                        loading={serviceTimeLoadingShort}
                                    />
                                </Grid>
                                <Grid item container justifyContent={"center"}>
                                    <ButtonSquare
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setShowTable(!showTable)}
                                    >
                                        {showTable ? "Скрыть все" : "Смотреть все"}
                                    </ButtonSquare>
                                </Grid>
                            </Grid>
                        </StyledCard>
                    </Grid>
                </Grid>
                <Grid item xs={7}>
                    {!showTable ? null : (
                        <Table
                            data={serviceTimeListLong?.items}
                            columns={columns}
                            loading={serviceTimeLoadingLong}
                            sortProps={serviceTimeSort}
                            pageIndex={serviceTimePage}
                            pageSize={serviceTimeRowsPerPage}
                            changeTableSort={changeTableSort}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            rowCount={serviceTimeListLong?.pagination.total! ?? 0}
                            enableRowActions={$loginUserStore.isAdmin()}
                            renderRowActionMenuItems={({ closeMenu, row }) => [
                                <MenuItem
                                    onClick={() => {
                                        $contractsDataStore.setEditServiceTimeData(row.original);
                                        $contractsDataStore.setEditServiceTime(true);
                                        closeMenu();
                                    }}
                                >
                                    Редактировать
                                </MenuItem>,
                                <MenuItem
                                    onClick={() => {
                                        $contractsDataStore.setEditServiceTimeData(row.original);
                                        setRmServiceTimeOpen(true);
                                        closeMenu();
                                    }}
                                >
                                    Удалить
                                </MenuItem>,
                            ]}
                        />
                    )}
                </Grid>
            </Grid>
            <Dialog open={mailDialogOpen} onClose={() => setMailDialogOpen(false)}>
                <Grid container spacing={1} sx={{ padding: "25px" }} justifyContent={"center"}>
                    <Grid item xs={12}>
                        <DialogTitle
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <Typography
                                variant={"h1"}
                                style={{
                                    fontSize: "34px",
                                    lineHeight: "36px",
                                    letterSpacing: "0",
                                    fontWeight: "600",
                                    color: "#20a0ff",
                                }}
                                component={"span"}
                            >
                                Отправка отчета на почту
                            </Typography>
                        </DialogTitle>
                    </Grid>
                    <Grid item xs={12} mb={1}>
                        <TextField
                            label="Электронная почта"
                            InputProps={{
                                type: "email",
                            }}
                            size="medium"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => setMail(e.target.value)}
                            value={mail}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent={"center"}>
                            <Grid item>
                                <ButtonSquare variant={"contained"} onClick={mailReport}>
                                    Отправить
                                </ButtonSquare>
                            </Grid>
                            <Grid item>
                                <ButtonSquare onClick={() => setMailDialogOpen(false)}>Отмена</ButtonSquare>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
            <Dialog open={rmServiceTimeOpen} onClose={() => setRmServiceTimeOpen(false)}>
                <Grid container spacing={1} sx={{ padding: "25px" }} justifyContent={"center"}>
                    <Grid item>
                        <DialogTitle
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <Typography
                                variant={"h1"}
                                style={{
                                    fontSize: "34px",
                                    lineHeight: "36px",
                                    letterSpacing: "0",
                                    fontWeight: "600",
                                    color: "#20a0ff",
                                }}
                                component={"span"}
                            >
                                Удаление времени облуживания
                            </Typography>
                        </DialogTitle>
                    </Grid>
                    <Grid item>
                        <DialogContent>{`Уверены что хотите удалить время обслуживания от ${formatDate(
                            editServiceTimeData.start_period,
                            "d.m.y"
                        )}?`}</DialogContent>
                    </Grid>
                    <Grid item container justifyContent={"center"}>
                        <Grid item>
                            <ButtonSquare onClick={closeServiceTimeDialog}>Отмена</ButtonSquare>
                        </Grid>
                        <Grid item>
                            <ButtonSquare variant={"contained"} onClick={rmServiceTime}>
                                Удалить
                            </ButtonSquare>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
            <StyledDialog
                open={progressOpen}
                PaperProps={{
                    sx: {
                        borderRadius: "12px",
                        boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
                        width: 692,
                        overflowY: "unset",
                    },
                }}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                maxWidth={false}
                scroll="body"
            >
                <Grid container direction={"column"}>
                    <Grid item container justifyContent={"center"}>
                        <DialogTitle>{"Формирование отчета"}</DialogTitle>
                    </Grid>
                    <Grid item>
                        <DialogContent>
                            <ProgressBar
                                action={"/GeoService/Reporting_progressReport"}
                                onSuccess={getReport}
                                cacheKey={cacheKey}
                            />
                        </DialogContent>
                    </Grid>
                </Grid>
            </StyledDialog>
            {$contractsDataStore.editServiceTime && <EditServiceTime />}
        </>
    );
});
export default ContractReport;
