import { Grid, MenuItem, Paper, TableContainer } from "@mui/material";
import { AddButton, SearchField } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import { $contractsDataStore } from "../../../../../store/ContractsStore";
import { $loginUserStore } from "../../../../../store/ResponseData";
import EditNormativeData from "./EditNormativeData";
import Table from "../../../../ui/table/Table";
import { type MRT_ColumnDef } from "material-react-table";
import { formatDate } from "../../../../../utils/utils";
import { Normative } from "../../../../../types/normativeData";

const NormativeData = observer(() => {
    const { normPage, normRowsPerPage, normSort, contractDetail, normSearch, normList, normLoadingTable } =
        $contractsDataStore;

    useEffect(() => {
        $contractsDataStore.getNormList(
            String(contractDetail?.guid),
            String(normPage + 1),
            normSort.by,
            normSort.order,
            String(normRowsPerPage),
            normSearch
        );
    }, [contractDetail?.guid, normPage, normRowsPerPage, normSort.by, normSort.order, normSearch]);

    const columns: MRT_ColumnDef<Normative>[] = [
        {
            accessorKey: "id",
            header: "№ п/п",
            size: 100,
            enableSorting: false,
            Cell: ({ row }) => <div>{normRowsPerPage * normPage + row.index + 1}</div>,
        },
        {
            accessorKey: "period",
            accessorFn: (row) => formatDate(row.start, "d.m.y") + " - " + formatDate(row.end, "d.m.y"),
            header: "Период",
            size: 300,
            enableSorting: false,
        },
        {
            accessorKey: "plan_workers",
            header: "Норматив чел. план",
            size: 300,
            enableSorting: false,
        },
        {
            accessorKey: "hours_worker",
            header: "Норматив чел/час план",
            size: 300,
            enableSorting: false,
        },
    ];

    const changeTableSort = useCallback(
        (key: string) => {
            if (key !== "status" && key !== "action") {
                $contractsDataStore.setNormSort({
                    by: key,
                    order: normSort.order === "asc" ? "desc" : "asc",
                });
            }
        },
        [normSort]
    );

    const handleChangePage = (v: number) => {
        $contractsDataStore.setNormPage(v);
    };

    const handleChangeRowsPerPage = (v: number) => {
        $contractsDataStore.setNormRowsPerPage(v);
        $contractsDataStore.setNormPage(0);
    };

    const handleEditNorm = () => {
        $contractsDataStore.setEditNorm(true);
    };

    return (
        <>
            <Grid container justifyContent={$loginUserStore.isAdmin() ? "space-between" : "flex-end"}>
                {$loginUserStore.isAdmin() ? (
                    <AddButton
                        onClick={handleEditNorm}
                        style={{
                            margin: 0,
                        }}
                    />
                ) : null}
                <SearchField
                    value={normSearch}
                    callback={(value) => $contractsDataStore.setNormSearch(value)}
                    callbackTimeout={700}
                    style={{
                        width: 300,
                    }}
                />
            </Grid>
            <TableContainer
                component={Paper}
                style={{
                    margin: "15px 0 0",
                }}
            >
                <Table
                    data={normList?.items}
                    columns={columns}
                    loading={normLoadingTable}
                    sortProps={normSort}
                    pageIndex={normPage}
                    pageSize={normRowsPerPage}
                    changeTableSort={changeTableSort}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowCount={normList?.pagination.total! ?? 0}
                    enableRowActions={$loginUserStore.isAdmin()}
                    renderRowActionMenuItems={({ closeMenu, row }) => [
                        <MenuItem
                            onClick={() => {
                                $contractsDataStore.setCurrentNormGuid(row.original?.guid ?? "");
                                handleEditNorm();
                                closeMenu();
                            }}
                        >
                            Изменить
                        </MenuItem>,
                    ]}
                />
            </TableContainer>
            {$contractsDataStore.editNorm && <EditNormativeData />}
        </>
    );
});
export default NormativeData;
