import { Dialog, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { ButtonSquare } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { $contractsDataStore } from "../../../../../store/ContractsStore";
import { useCallback, useState } from "react";
import { saveServiceTime } from "../../../../../api-actions/ContractsApi";
import { ServiceTime } from "../../../../../types/serviceTimes";
import dayjs from "dayjs";
import DatePicker from "../../../../ui/DatePicker";
import TimePicker from "../../../../ui/TimePicker";
import { DEFAULT_SERVICE_TIME } from "../../../../../utils/const";

const StyledDialog = styled(Dialog)({
    height: "auto",
    padding: "15px",
    margin: "0 auto",
});

const EditServiceTime = observer(() => {
    const { contractDetail, editServiceTimeData, serviceTimePage, serviceTimeSort, serviceTimeRowsPerPage } =
        $contractsDataStore;
    const currentDate = new Date().toISOString().split("T")[0];

    const [form, setForm] = useState<ServiceTime>({
        ...editServiceTimeData,
        contract: !editServiceTimeData.contract ? String(contractDetail?.guid) : editServiceTimeData.contract,
        start: editServiceTimeData.start ? `${currentDate} ${editServiceTimeData.start}` : "",
        end: editServiceTimeData.end ? `${currentDate} ${editServiceTimeData.end}` : "",
    });

    const [errors, setErrors] = useState({
        start: "",
        end: "",
        start_period: "",
    });

    const setFormValue = useCallback(
        (name: string, value: string) => {
            setForm({ ...form, [name]: value });
        },
        [form]
    );

    const handleSubmit = async () => {
        const errors = {
            start: "",
            end: "",
            start_period: "",
        };

        if (!form.start) {
            errors.start = "Поле обязательное для заполнения";
        }
        if (!form.end) {
            errors.end = "Поле обязательное для заполнения";
        }

        if (!form.start_period) {
            errors.start_period = "Поле обязательное для заполнения";
        }

        if (Object.values(errors).filter((item) => !!item).length) {
            setErrors(errors);
            return;
        }

        $contractsDataStore.setLoading(true);
        await saveServiceTime(form).then(() => {
            $contractsDataStore.getServiceTimeList(
                String(contractDetail?.guid),
                String(serviceTimePage + 1),
                serviceTimeSort.by,
                serviceTimeSort.order,
                String(serviceTimeRowsPerPage)
            );
            $contractsDataStore.setEditServiceTime(false);
        });
        clearForm();
    };

    const clearForm = () => {
        setForm(DEFAULT_SERVICE_TIME);
        $contractsDataStore.setEditServiceTimeData(DEFAULT_SERVICE_TIME);
    };

    return (
        <StyledDialog
            open={$contractsDataStore.editServiceTime}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
                    width: 692,
                    overflowY: "unset",
                },
            }}
            keepMounted={true}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            maxWidth={false}
            scroll="body"
        >
            <IconButton
                aria-label="close"
                style={{
                    position: "absolute",
                    right: "-45px",
                    top: "-45px",
                    color: "white",
                }}
                onClick={() => {
                    $contractsDataStore.setEditServiceTime(false);
                    clearForm();
                }}
            >
                <CloseIcon fontSize="large" />
            </IconButton>
            <Grid container justifyContent={"center"}>
                <Grid item xs={9}>
                    <Grid container direction={"column"} justifyContent={"center"} spacing={2}>
                        <Grid item xs={7}>
                            <DialogTitle
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Typography
                                    variant={"h1"}
                                    style={{
                                        fontSize: "34px",
                                        lineHeight: "36px",
                                        letterSpacing: "0",
                                        fontWeight: "600",
                                        color: "#20a0ff",
                                    }}
                                    component={"span"}
                                >
                                    {(form.guid ? "Изменить " : "Добавить ") + "время оказания услуг"}
                                </Typography>
                            </DialogTitle>
                        </Grid>
                        <Grid item container spacing={1} direction={"row"} justifyContent={"space-between"}>
                            <Grid item>
                                <TimePicker
                                    value={form.start ? dayjs(form.start) : null}
                                    label="Начало периода"
                                    slotProps={{
                                        textField: {
                                            clearable: true,
                                            error: !!errors.start,
                                            helperText: errors.start,
                                            sx: {
                                                width: "250px",
                                            },
                                        },
                                    }}
                                    onChange={(value) => {
                                        setErrors({ ...errors, start: "" });
                                        setFormValue("start", value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : "");
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <TimePicker
                                    value={form.end ? dayjs(form.end) : null}
                                    label="Окончание периода"
                                    slotProps={{
                                        textField: {
                                            clearable: true,
                                            error: !!errors.end,
                                            helperText: errors.end,
                                            sx: {
                                                width: "250px",
                                            },
                                        },
                                    }}
                                    onChange={(value) => {
                                        setErrors({ ...errors, end: "" });
                                        setFormValue("end", value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : "");
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent={"flex-end"}>
                            <DatePicker
                                value={form.start_period ? dayjs(form.start_period) : null}
                                label="Дата"
                                slotProps={{
                                    textField: {
                                        clearable: true,
                                        error: !!errors.start_period,
                                        helperText: errors.start_period,
                                        sx: {
                                            width: "250px",
                                        },
                                    },
                                }}
                                onChange={(value) => {
                                    setErrors({ ...errors, start_period: "" });
                                    setFormValue("start_period", value ? dayjs(value).format("YYYY-MM-DD") : "");
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <ButtonSquare
                                disabled={!!errors.start || !!errors.end}
                                variant="contained"
                                color="primary"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: "15px auto",
                                }}
                                onClick={handleSubmit}
                            >
                                {form.guid ? "Изменить" : "Добавить"}
                            </ButtonSquare>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledDialog>
    );
});
export default EditServiceTime;
