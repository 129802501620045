import { ArrowForwardIos } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { type MRT_ColumnDef } from 'material-react-table';
import { observer } from 'mobx-react';
import {
    useCallback,
    useMemo,
} from 'react';
import { $monitoringStore } from '../../../store/MonitoringStore';
import { Level } from '../../../types/ui/table';
import Table from '../../ui/table/Table';

const StyledUserInfo = styled(Box)<{
    open?: boolean;
}>(({ theme, open }) => ({
    width: document.body.clientWidth < 900 ? 'calc(100vw - 25px)' : 500,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        width: document.body.clientWidth < 900 ? 'calc(100vw - 25px)' : 500,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    }),
    ...(!open && {
        width: 0,
        marginRight: open ? "15px" : 0,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    })
}));

const UserInfo = observer(() => {
    const {
        usersDrawerIsOpen,
        usersData,
        Markers,
        loadingUsersData
    } = $monitoringStore;

    const scrollToMarker = useCallback(
        (event: any) => {
            const userId = event.target.dataset.id;
            Markers.map((item) => {
                if (item.id === userId) {
                    $monitoringStore.setCurrentMarker(item.marker);
                }
                return item;
            });
        },
        [Markers]
    );

    function transformJsonToArray(json: any) {
        function processItems(items: any) {
            let result: Level[] = [];

            for (let key in items) {
                let item = items[key];
                let newItem: any = {
                    ...item,
                    items: item.items ? processItems(item.items) : []
                };
                result.push(newItem);
            }
            return result;
        }
        return processItems(json);
    }

    const data = useMemo(() => transformJsonToArray(usersData), [usersData]);
    const columns = useMemo<MRT_ColumnDef<Level>[]>(
        () => [
            {
                accessorKey: "name",
                header: "Сотрудники",
                size: document.body.clientWidth < 900 ? 30 : 200,
                muiTableBodyCellProps: {
                    size: "small",
                    padding: "none"
                },
                muiTableHeadCellProps: {
                    size: "small",
                    padding: "none",
                    align: "center"
                },
                Cell: ({ renderedCellValue, row }) => {
                    const points = Markers.filter((item) => {
                        return item.id === row.original.guid;
                    });

                    if (row.original.type === "user" && points.length > 0) {
                        return (
                            <Box
                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: {
                                        xs: 150 - 16 * row.depth + "px",
                                        md: 300 - 16 * row.depth + "px",
                                    },
                                    whiteSpace: "nowrap",
                                    marginLeft: 8 * row.depth + "px",
                                    cursor: "pointer"
                                }}
                                data-id={row.original.guid}
                                onClick={scrollToMarker}
                                title={row.original.full_name}
                            >
                                {row.original.name}
                            </Box>
                        );
                    } else {
                        return (
                            <Box
                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: {
                                        xs: 150 - 16 * row.depth + "px",
                                        md: 300 - 16 * row.depth + "px",
                                    },
                                    whiteSpace: "nowrap",
                                    marginLeft: 8 * row.depth + "px"
                                }}
                                title={row.original.full_name}
                            >
                                {row.original.name}
                            </Box>
                        );
                    }
                }
            },
            {
                accessorKey: "count",
                header: "План/Факт",
                size: 30,
                muiTableBodyCellProps: {
                    size: "small",
                    padding: "none"
                },
                muiTableHeadCellProps: {
                    size: "small",
                    padding: "none",
                    align: "center"
                },
                Cell: ({ renderedCellValue, row }) => {
                    if (row.original.type !== "user") {
                        return (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                {row.original.count +
                                    "/" +
                                    row.original.active +
                                    "/" +
                                    row.original.inactive}
                            </Box>
                        );
                    }
                }
            }
        ],
        [Markers, scrollToMarker]
    );

    return (
        <>
            <Button
                variant="outlined"
                onClick={() => $monitoringStore.toggleDrawer()}
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: 100,
                    minWidth: 25,
                    zIndex: 99999,
                    lineHeight: 1,
                    padding: '10px 0',
                    background: 'white',
                    border: '1px solid #5ac8fa',
                    borderRadius: '10px 0 0 10px',
                    display: {
                        xs: usersDrawerIsOpen ? 'block' : 'none',
                        md: 'none'
                    }
                }}
            >
                <ArrowForwardIos />
            </Button>
            <Box
                sx={{
                    display: usersDrawerIsOpen ? 'flex' : 'none',
                    position: "absolute",
                    zIndex: 999,
                    height: "100%",
                    boxShadow: "0px 0px 5px 5px rgba(0, 0, 0, 0.2)",
                    right: 0,
                    left: {
                        xs: 25,
                        md: 'auto',
                    },
                    top: {
                        xs: 90,
                        md: 0,
                    },
                }}
            >
                <StyledUserInfo open={usersDrawerIsOpen}>
                    <Box
                        sx={{
                            width: {
                                xs: '100%',
                                md: 500,
                            },
                            height: "100%",
                            marginLeft: 0
                        }}
                    >
                        <Table
                            data={data}
                            columns={columns}
                            loading={loadingUsersData}
                            enableExpanding
                        />
                    </Box>
                </StyledUserInfo>
            </Box>
        </>
    );
});

export default UserInfo;
